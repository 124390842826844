import { withIcon } from "#icons/_withIcon";

export const EmailFill = withIcon(
  () => (
    <path d="M8 8.44013L6.336 7.06413L0.576 13.0161H15.296L9.632 7.06413L8 8.44013ZM10.432 6.42413L15.936 12.1521C15.936 12.0881 15.968 12.0561 15.968 11.9921C15.968 11.9281 15.968 11.8641 15.968 11.8001V1.91213L10.432 6.42413ZM0 1.91213V11.8321C0 11.8961 0 11.9601 0 12.0241C0 12.0881 0.032 12.1201 0.032 12.1841L5.568 6.48813L0 1.91213ZM15.488 0.984131H0.512L8 7.00013L15.488 0.984131Z" />
  ),
  {
    viewBox: "0 0 16 14",
  },
);
