/**
 * - To add more copy utilities for non-text fields:
 * https://web.dev/async-clipboard/
 * - __Note:__ the Clipboard API is only supported for pages served over `HTTPS`.
 *
 * @param text - The text to copy to the clipboard.
 * @param onSuccess - Callback function to execute on successful copy.
 * @param onError - Callback function to execute on an error during copy.
 * 
 */
export async function copyTextToClipboard(
  text: string,
  onSuccess: () => void = () => {},
  onError: () => void = () => {},
): Promise<void> {
  if ("clipboard" in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      onSuccess();
    } catch(e) {
      onError();
    }
  } else {
    try {
      // Note: document.execCommand is deprecated and may not work in all browsers
      const successful = document.execCommand("copy", true, text);
      if (successful) {
        onSuccess();
      } else {
        onError();
      }
    } catch(e) {
      console.log("Unable to copy to clipboard. (document.exeCommand)", e);
      onError();
    }
  }
}
